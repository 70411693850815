.componentBase {
  font-family: var(--font-family-base);
}

.componentXs {
  font-size: var(--font-size-32);
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}

.componentSm {
  font-size: var(--font-size-40);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-block);
}

.componentMd {
  font-size: var(--font-size-70);
  font-size: var(--font-size-40-70);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}

.componentLg {
  font-size: var(--font-size-120);
  font-size: var(--font-size-70-120);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}

.componentXl {
  font-size: var(--font-size-120);
  font-size: var(--font-size-70-120);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}

.componentXxl {
  font-size: var(--font-size-240);
  font-size: var(--font-size-70-240);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}

.componentXxxl {
  font-size: var(--font-size-270);
  font-size: var(--font-size-70-270);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);
}
