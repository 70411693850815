.componentBase,
.componentLinkBase,
.componentReverseBase,
.componentLinkReverseBase {
  --translate-icon: 0;

  display: flex;
  align-items: center;
  gap: var(--size-16);
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-400);
  letter-spacing: var(--letter-spacing);
  line-height: var(--line-height-normal);

  &:hover {
    --translate-icon: var(--size-8);
  }

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.componentReverseBase,
.componentLinkReverseBase {
  flex-direction: row-reverse;

  &:hover {
    --translate-icon: calc(-1 * var(--size-8));
  }
}

.componentBlue,
.componentLinkBlue,
.componentBlueReverse,
.componentLinkBlueReverse {
  color: var(--color-blue-900);
  font-size: var(--font-size-32);
}

.componentWhite,
.componentLinkWhite {
  color: var(--color-white);
}

.componentLinkBlueSm {
  font-size: var(--font-size-22-32);
}

.componentLinkWhiteXs {
  position: relative;
  gap: var(--size-12);
  font-size: var(--font-size-16);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  & > .icon {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.icon {
  transform: translateX(var(--translate-icon));
  transition: transform var(--duration-250) var(--ease-out-quad);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
